.bg-dark {
  background-color: #0771b8 !important;
}
.nav-link.active {
  background-color: #0771b8 !important;
}

html {
  overflow-y: scroll;
}

a {
  color: #000000;
}

label {
  margin-bottom: 0;
}

h6 {
  margin-bottom: 0;
}

.form-group {
  margin-bottom: 0;
}

.title {
  margin: 1rem 1rem 1rem 0rem;
}

.container-fluid {
  margin: 0 auto;
  max-width: 1800px;
}

.btn.refresh {
  font-size: 1.4rem;
}

.search-input {
  padding: 0.5rem 0.4rem 0.4rem 0.8rem;
  border: none;
  flex-grow: 1;
  width: 75%;
  max-width: 75%;
}

.Toastify__toast-container--top-right {
  top: 75px;
}

.Toastify__toast--info,
.Toastify__toast--success,
.Toastify__toast--error {
  border-radius: 0.25rem;
}

body.modal-open {
  overflow: hidden !important;
}

/*Optional:*/
.modal-open,
.modal {
  padding-right: 0 !important;
}

.text-invalid {
  color: #bb1248;
}
.input-field-invalid {
  color: #bb1248;
  border: 2px solid #bb1248;
}

.input-field-invalid:focus {
  color: #bb1248;
  border: 2px solid #bb1248;
}

.row-selected {
  background-color: #8ac7f0 !important;
}

.react-contextmenu-wrapper {
}

.react-contextmenu {
  min-width: 160px;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.react-contextmenu-item {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.react-contextmenu-item:hover {
  cursor: pointer;
  background-color: rgb(222, 222, 222);
}

.checkbox-table-cell {
  width: 10px;
}

.checkbox-label-width {
  width: 100px;
}

.search-select {
  box-shadow: 0;
  width: 125px;
  height: 48px;
  background-color: rgb(248, 248, 248);
}
