.login {
  padding-top: 10%;
  width: 400px;
  margin: 0 auto;
}
.login-card {
  background-color: #f7f7f7;
}

label {
  font-size: 20px;
}

.form-group {
  margin: 0.5rem;
}

.login-button {
  margin: 0.5rem;
}
