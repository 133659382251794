.raw-messages-table-body {
  word-break: break-all;
}

.raw-messages {
  display: flex;
}

.search-select-messages {
  box-shadow: 0;
  width: 150px;
  height: 48px;
  background-color: rgb(248, 248, 248);
  border: none;
  font-weight: 500;
  border-right: 1px solid #e0e3e6;
  border-radius: 0.25rem;
}
