.nav-item.nav-link.active {
  color: white;
}

.nav-tabs {
  padding: 0.75rem;
  color: white;
}

.create-configuration-button {
  float: right;
}
