.sort-button {
  background-color: white;
  border: none;
  color: black;
  margin: 0;
  padding: 0;
  padding-left: 5px;
  text-decoration: none;
}

button:focus {
  outline: 0;
}

.btn.refresh-border {
  border-left: 1px solid #e0e3e6;
  border-radius: 0.25rem;
}

.search {
  display: flex;
  width: 100%;
  /* align-items: center; */
}

.result {
  width: 25%;
  padding: 0.6rem;
  text-align: right;
  flex-grow: 1;
  font-size: larger;
}

input:focus {
  outline: 0;
}

.card-style {
  background-color: #e9ecef;
  margin: 0rem 1rem 1rem 1rem;
  padding: 1rem;
}
.form-check-input {
  width: 20px;
  height: 20px;
}

.radio {
  margin-right: 3rem;
  display: inline;
}

label {
  padding-left: 0.5rem;
  font-size: 16px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  border: 1px solid;
  padding: 10px;
  border: solid white;
  -webkit-appearance: none;
  -moz-box-shadow: 0px 2px 15px 3px rgba(0, 0, 0, 0.4) !important;
  -webkit-box-shadow: 0px 2px 15px 3px rgba(0, 0, 0, 0.4) !important;
  box-shadow: 0px 2px 15px 3px rgba(0, 0, 0, 0.4) !important;
  background: rgb(255, 255, 255);
}

.footer-devices-text {
  font-size: 100%;
  font-weight: 500;
  text-align: right;
  line-height: 15px;
}

.devices-table {
  font-size: 90%;
  min-width: 1755px;
}

.device-table-customer {
  overflow: hidden;
  width: 125px;
}

.device-table-inSync {
  overflow: hidden;
  width: 100px;
}
