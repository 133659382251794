.log {
  display: flex;
}

.timeStamp-table-cell {
  width: 7.1em;
  min-width: 7.1em;
  max-width: 7.5em;
  word-break: break-all;
}

.log-table-cell {
  width: 140vh;
  min-width: 140vh;
  max-width: 140vh;
}
