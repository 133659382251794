.create-customer-div {
  margin: 0.5rem 0rem 0.5rem 0.5rem;
  float: right;
  width: 100%;
}
.create-customer-button {
  float: right;
}

.search-select-customer {
  box-shadow: 0;
  width: 150px;
  height: 48px;
  background-color: rgb(248, 248, 248);
  border: none;
  font-weight: 500;
  border-right: 1px solid #e0e3e6;
  border-radius: 0.25rem;
}
